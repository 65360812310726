import {defineStore} from "pinia";
import {computed, ref} from "vue";
import type {Sort} from "@/types";
import {useI18n} from "vue-i18n";

export const useSortsStore = defineStore('sorts', () => {
    const { t } = useI18n();

    const sortTypes: {[value: string]: Sort} = {
        'recommended': {label: t('toolbar.recommended'), value: 'recommended'},
        'price-low': {label: t('toolbar.price_low_to_high'), value: 'price-low'},
        'price-high': {label: t('toolbar.price_high_to_low'), value: 'price-high'},
        'brand-az': {label: t('toolbar.brand_az'), value: 'brand-az'},
        'brand-za': {label: t('toolbar.brand_za'), value: 'brand-za'},
    }

    const currentSortRef = ref<Sort>(sortTypes['recommended']);
    const currentSort = computed({
        get: () => currentSortRef.value,
        set: (sort) => (currentSortRef.value = sort)
    });

    function resetSorts() {
        currentSort.value = sortTypes['recommended'];
    }

    return {
        sortTypes,
        currentSort,
        resetSorts
    };
});
