<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useFiltersStore } from '@/stores/filters';
import { useDataStore } from '@/stores/data';
import { usePaginationStore } from '@/stores/pagination';
import { useSortsStore } from '@/stores/sorts';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const filtersStore = useFiltersStore();
const { filters } = storeToRefs(filtersStore);
const dataStore = useDataStore();
const router = useRouter();
const paginationStore = usePaginationStore();
const sortsStore = useSortsStore();
const { t } = useI18n();

dataStore.loadCarMeta();

const props = defineProps({
    isHomeView: {
        default: false,
    },
});

const fuelTypes = computed(() => {
    return dataStore.fuelTypes;
});

const activeFuelTypes = ref<string[]>(fuelTypes.value);
const activeFuelTypesComputed = computed(() => {
    return activeFuelTypes.value;
});

function onFormSubmit(event: any) {
    event.preventDefault();

    filtersStore.setNullToDefaultValues();

    paginationStore.resetPagination();
    sortsStore.resetSorts();

    router.push({ name: 'suggested-cars' });
}

onMounted(() => {
    dataStore.loadCarMeta();

    activeFuelTypes.value = dataStore.activeFuelTypes(
        filters.value['car-brand'].value,
        filters.value['car-model'].value,
        filters.value['car-version'].value
    );
});

watch(
    [
        filters.value['car-brand'],
        filters.value['car-model'],
        filters.value['car-version'],
    ],
    ([newBrand, newModel, newVersion]) => {
        if (typeof newModel.value === 'object') {
            newModel.value = '';
        } else if (typeof newVersion.value === 'object') {
            newVersion.value = '';
        }

        activeFuelTypes.value = dataStore.activeFuelTypes(
            newBrand.value,
            newModel.value,
            newVersion.value
        );
    },
    { deep: true }
);

// Deselect fuel type if it becomes inactive.
watch(
    () => activeFuelTypes.value,
    (newValue) => {
        const fuelTypeValues = filters.value['fuel-type'].value.map(
            (f) => f.value
        );
        const intersect = newValue.filter((x) => fuelTypeValues.includes(x));
        for (const type of fuelTypeValues) {
            if (!intersect.includes(type)) {
                filtersStore.removeFilter('fuel-type', type);
            }
        }
    }
);

const isSubmitButtonEnabled = computed(() => {
    return (
        filters.value['car-brand'].value &&
        filters.value['car-brand'].value != ''
    );
});
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
                <form class="form" @submit="onFormSubmit">
                    <h2 :class="{ 'pt-4': isHomeView, 'pt-0': !isHomeView }">
                        {{ $t('search_by_brand.title') }}
                    </h2>
                    <div class="form__group">
                        <div class="form__row">
                            <label class="form__label">
                                {{ $t('search_by_brand.sub_title') }}
                            </label>
                            <p class="form__label light left">
                                {{ $t('search_by_brand.optional') }}
                            </p>
                        </div>
                        <div class="row">
                            <div
                                class="col-6 col-md-3"
                                v-for="fuelType in fuelTypes"
                                :key="fuelType"
                            >
                                <div class="selector">
                                    <input
                                        class="selector__input"
                                        type="checkbox"
                                        :id="'selectorRadio' + fuelType"
                                        :value="{
                                            title: filters[
                                                'fuel-type'
                                            ].toFormatted(fuelType),
                                            value: fuelType,
                                        }"
                                        v-model="filters['fuel-type'].value"
                                        :disabled="
                                            !activeFuelTypesComputed.includes(
                                                fuelType
                                            )
                                        "
                                    />
                                    <label
                                        class="selector__tile"
                                        :for="'selectorRadio' + fuelType"
                                    >
                                        <i :class="'as-icon-' + fuelType"></i>
                                        {{ $t('fuel_type.' + fuelType) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form__group">
                        <label class="form__label">
                            {{ $t('search_by_brand.question_brand') }}
                        </label>
                        <v-select
                            :placeholder="$t('common.select_option')"
                            label="title"
                            v-model="filtersStore.filters['car-brand'].value"
                            :options="dataStore.filteredMetaTree.brands"
                            :deselectFromDropdown="true"
                        ></v-select>
                    </div>

                    <div class="form__group">
                        <div class="form__row">
                            <label class="form__label">
                                {{ $t('search_by_brand.question_model') }}
                            </label>
                            <p class="form__label light left">
                                {{ $t('search_by_brand.optional') }}
                            </p>
                        </div>
                        <v-select
                            :placeholder="$t('common.select_option')"
                            label="title"
                            v-model="filtersStore.filters['car-model'].value"
                            :options="dataStore.filteredMetaTree.models"
                            :deselectFromDropdown="true"
                        ></v-select>
                    </div>

                    <div class="form__group">
                        <div class="form__row">
                            <label class="form__label">
                                {{ $t('search_by_brand.question_version') }}
                            </label>
                            <p class="form__label light left">
                                {{ $t('search_by_brand.optional') }}
                            </p>
                        </div>
                        <v-select
                            :placeholder="$t('common.select_option')"
                            label="title"
                            v-model="filters['car-version'].value"
                            :options="dataStore.filteredMetaTree.versions"
                            :deselectFromDropdown="true"
                        ></v-select>
                    </div>
                    <div class="form__buttons--center">
                        <input
                            type="submit"
                            class="button"
                            :value="$t('buttons.see_results')"
                            :disabled="!isSubmitButtonEnabled"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
